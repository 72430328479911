import React from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { RegularLinks } from "./button"

const useStyles = makeStyles(theme => ({
  textContainer: {
    display: "flex",
    padding: "5% 20% 1% 20%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "10% 5% 5% 5%",
    },
  },
  since: {
    fontFamily: '"Noto Sans KR", sans-serif',
    fontSize: "0.7em",
    color: "#7b7a7a",
    marginTop: "8px",
    marginLeft: "3%",
  },
  brand: {
    fontFamily: '"Karla", sans-serif',
    fontSize: "1em",
    display: "block",
    letterSpacing: "0.1em",
    marginBottom: 0,
    marginLeft: "3%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  sectionTitle: {
    fontFamily: '"Nanum Myeongjo", serif',
    marginLeft: "3%",
    fontSize: "2em",
    color: "#484646",
  },
  venuewrapper: {
    background: "rgba(255, 255, 255, 0.1)",
    padding: "10% 5%",
    justifyContent: "space-between",
  },
  weddingplace: {
    fontFamily: '"Noto Sans KR", sans-serif',
    fontSize: "0.8em",
    color: "#565656",
    lineHeight: "2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  leftLine: {
    borderLeft: "solid 1px rgba(255, 255, 255, 0.3)",
    paddingLeft: "1em",
  },
}))

export const HistoryBlock = () => {
  const classes = useStyles()

  return (
    <>
      <Grid container className={classes.textContainer} spacing={4}>
        <Grid item sm={12} xs={12}>
          <h3 className={classes.sectionTitle}>History</h3>
        </Grid>
        <Grid item sm={6} xs={12}>
          <p className={classes.brand}>
            <span>as</span> Magan Project <br /> (incl. magan flower)
          </p>
          <p className={classes.since}>since 2015</p>
          <Grid container className={classes.venuewrapper}>
            <Grid item sm={6} xs={6}>
              <p className={classes.weddingplace}>
                더클래스 청담 <br />
                이비스 앰베서더 명동 <br />
                이비스 스타일 루프탑 <br />
                메이다이닝 <br />
                스미스가 좋아하는 한옥 <br />
                경원재 앰베서더 <br />
                다담에뜰 <br />
                아델라한옥 <br />
                가예헌 <br />
                힐하우스 <br />
                쿤스트할레 <br />
                서원아트리움 <br />
                마켓오 <br />
                에덴파라다이스호텔 <br />
                이음더플레이스 <br />
                스톤힐 <br />
                핸더스 <br />
                삼원가든 <br />
                온즈드롬 <br />
                더리버 <br />
                서울마리나 <br />
              </p>
            </Grid>
            <Grid item sm={6} xs={6} className={classes.leftLine}>
              <p className={classes.weddingplace}>
                역삼아르누보 호텔 <br />
                대홍관 <br />
                카페엠 <br />
                동보성 <br />
                강릉 카페나인 <br />
                그라운드 62 <br />
                시흥 마르레스토랑 <br />
                C156 <br />
                신라스테이 <br />
                어반가든 <br />
                잭비님블 <br />
                청담펜타리움 <br />
                대청쌍청웨딩홀 <br />
                파파드쿠진 <br />
                Latin bar River <br />
                어반가든 <br />
                카페마고 <br />
                피카소 레스토랑 <br />
                라벨라빌라 <br />
                영주 작은행복레스토랑 <br />
                울진 구산해수욕장 <br />외 다수
              </p>
            </Grid>
          </Grid>
          <RegularLinks to="/portfolio" linkName="프로젝트 보기" />
        </Grid>
      </Grid>
    </>
  )
}
