import React, { useRef, useEffect } from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { HistoryBlock } from "../components/history"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Logo } from "../components/logo"
import SEO from "../components/seo"
import website from "../../config/website"
import gsap from "gsap"
import { Power3 } from "gsap/gsap-core"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    padding: "5% 18% 5% 18%",
    width: "100%",
    backgroundColor: "#fff",
    height: "940px",
    overflow: "hidden",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5% 5% 5%",
      marginTop: "25%",
    },
  },
  secondContainer: {
    display: "flex",
    padding: "5% 18% 5% 18%",
    backgroundColor: "#fff",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5% 5% 5%",
    },
  },
  textContainer: {
    backgroundColor: "rgba(239, 239, 239, 0.5)",
    padding: "10%",
    color: "#616161",
    fontSize: "0.85em",
    lineHeight: 2,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7em",
    },
  },
  headTitle: {
    fontSize: "2.5em",
    fontFamily: '"Nanum Myeongjo", serif',
    color: "#484646",
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6em",
    },
  },
  subTitle: {
    fontSize: "1em",
    color: "#4c4c4c",
    fontWeight: "300",
    lineHeight: 2,
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
  },
  imgContainer: {
    marginTop: "15%",
    height: "100vh",
    position: "relative",
    overflow: "hidden",
    background: "#fff",
    opacity: 0,
  },
  mainImg: {
    height: "60vh",
    objectFit: "cover",
    opacity: 1,
    mixBlendMode: "luminosity",
    "&:hover": {
      mixBlendMode: "unset",
      transition: "mixBlendMode 1.5s ease",
    },
  },
  about: {
    fontSize: "4em",
    position: "absolute",
    top: "53%",
    left: "500px",
    fontFamily: '"Nanum Myeongjo", serif',
    color: "#e0d1d1",
    fontWeight: "600",
    lineHeight: 1,
    mixBlendMode: "difference",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4em",
      right: "-75%",
    },
  },
  conceptImages: {
    width: "280px",
    height: "280px",
    objectFit: "cover",
    marginTop: "15%",
    mixBlendMode: "luminosity",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      height: "150px",
      mixBlendMode: "unset",
    },
    "&:hover": {
      mixBlendMode: "unset",
    },
  },
  featuresPara: {
    fontSize: "0.88em",
    fontWeight: 300,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
  },
  strong: {
    fontWeight: 400,
    color: "#565656",
  },
}))

export default ({ data }) => {
  const classes = useStyles()
  const mainImgWrapper = useRef(null)
  const about = useRef(null)

  useEffect(() => {
    gsap.to(mainImgWrapper.current, 1, {
      opacity: 1,
      ease: Power3.easeOut,
      y: -50,
      delay: 1.4,
    })
    gsap.to(about.current, 2, {
      ease: Power3.easeOut,
      x: "-200%",
      y: -50,
      delay: 0.8,
    })
  }, [])

  return (
    <Layout>
      <SEO
        title={`매건프로젝트 소개 | ${website.titleAlt}`}
        desc="아름답고 트렌디한 웨딩/이벤트 기획 및 디렉팅, 플라워 데코레이션과 이미지 및 구조물 디자인/제작을 제공하는 매건 프로젝트(구 매건플라워)를 소개합니다."
      />
      <Grid container className={classes.container}>
        <Grid
          item
          sm={12}
          xs={12}
          className={classes.imgContainer}
          ref={mainImgWrapper}
        >
          <Img
            fluid={data.aboutMain1.childImageSharp.fluid}
            className={classes.mainImg}
            alt="매건프로젝트 작업 모습"
          />
          <div className={classes.about} ref={about}>
            About
            <br /> Magan
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.container}>
        <Grid item sm={12} xs={12}>
          <h3 className={classes.headTitle}>Welcome to Magan Project</h3>
          <p className={classes.subTitle}>
            상상 속의 웨딩을 현실로 바꿔드립니다.
            <br /> 트렌디 디자인, 디렉팅 전문 매건프로젝트
          </p>
        </Grid>
        <Grid item sm={4}>
          <img
            className={classes.conceptImages}
            src="https://images.unsplash.com/photo-1491245338813-c6832976196e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            alt="커스텀디자인"
          />
          <p>All Custom Design</p>
          <p className={classes.featuresPara}>
            남들과 똑같은 웨딩이 아닌
            <br />
            고객의 꿈과 매건 프로젝트의 스타일이 <br />
            만나&nbsp;
            <span className={classes.strong}>하나뿐인 디자인 프로젝트</span>
            로 <br />
            꿈을 실현해 드립니다.
          </p>
        </Grid>
        <Grid item sm={4}>
          <img
            className={classes.conceptImages}
            src="https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            alt="합리적인 가격"
          />
          <p>Reasonable Price</p>
          <p className={classes.featuresPara}>
            막연하게 높은 가격을 지양하고
            <br /> 신랑신부님의 예산에 맞춰
            <br />
            <span className={classes.strong}>합리적이고 수용가능한 가격</span>
            으로 <br />
            최상의 결과를 낼 수 있는데 집중합니다.
          </p>
        </Grid>
        <Grid item sm={4}>
          <img
            className={classes.conceptImages}
            src="https://images.unsplash.com/photo-1468779036391-52341f60b55d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1521&q=80"
            alt="디렉팅 서비스"
          />
          <p>A - Z Care</p>
          <p className={classes.featuresPara}>
            장소, 준비, 예산, 업체 선정, 당일 관리 등
            <br /> 웨딩이 처음이신 신랑신부님이 마음놓을 수 있도록
            <br />
            <span className={classes.strong}>
              처음부터 끝까지 함께하는
              <br />
              디렉팅 서비스
            </span>
            를 제공합니다.
          </p>
        </Grid>
      </Grid>
      <Grid container className={classes.secondContainer}>
        <Grid item sm={12} className={classes.textContainer}>
          <Logo width={"100px"} />
          <p>
            매건 프로젝트(前 매건 플라워)는 공간 스타일링 및 화훼를 전공한
            특성을 바탕으로
            <br /> 약 10년간 다양한 종류의 장소에서 웨딩 스타일링과 디렉팅을
            해왔습니다.
          </p>
          <p>
            호텔, 하우스웨딩홀, 웨딩홀, 레스토랑, 카페, 루프탑, 한옥, 해변까지
            두 분의 소중한 공간이라면 어떠한 장소에서도 신랑신부님과 가족, 하객
            분들에게 따뜻함과 아름다움이 느껴지는 웨딩을 만들어 드리고 있습니다.
            또한 스타일링 뿐만 아니라 장소 섭외와 추천, 식순 안배, 관련 업체와의
            협업, 예산 맞춤형 설계, 당일 현장 진행 등 웨딩 디렉팅 서비스도
            제공하고 있습니다.
          </p>
          <p>
            공간에 대한 빠른 이해와 이에 걸맞은 최적의 레이아웃, 공간을 둘러싼
            색감과 가장 잘 어울리는 컬러조합을 바탕으로 가장 트렌디하면서도
            세련된, 그러면서도 따뜻함을 잃지 않는 스타일링으로 고객분들에게 100%
            이상의 만족을 전달 드리겠습니다.
          </p>
          <p>
            웨딩 뿐만 아니라 기업행사, 각종 이벤트 등 공간 디자인과 프로그램이
            필요한 모든 곳에서도 다양한 활동을 해왔습니다. 단순한 플라워 세팅
            위주의 데코레이션이 아닌 기업의 브랜드 이미지에 부합하는 인쇄
            디자인, 구조물 제작 및 설치까지, 담당자의 만족도를 제고시킬 수
            있도록 최선을 다하고 있습니다.
          </p>
          <p>감사합니다.</p>
        </Grid>
      </Grid>
      <HistoryBlock />
    </Layout>
  )
}

export const query = graphql`
  {
    aboutMain1: file(relativePath: { eq: "magan_working.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
